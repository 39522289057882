import { Admin, Resource } from "react-admin";
import {
  buildAuthProvider,
  buildDataProvider,
  CognitoGroupList,
  CognitoUserList,
  CognitoUserShow,
} from "react-admin-amplify";
import FeedIcon from "@mui/icons-material/Feed";
import UserIcon from "@mui/icons-material/People";
import awsExports from "./aws-exports";
import { LoginPage } from "./components/LoginPage";
import { NewsCreate, NewsEdit, NewsList, NewsShow } from "./components/News";

import { UserCreate, UserEdit, UserList, UserShow } from "./components/User";
import * as mutations from "./graphql/mutations";
import * as queries from "./graphql/queries";

const authProvider = buildAuthProvider({
  authGroups: [],
});

const dataProvider = buildDataProvider(
  {
    queries,
    mutations,
  },
  {
    storageBucket: awsExports.aws_user_files_s3_bucket,
    storageRegion: awsExports.aws_user_files_s3_bucket_region,
    enableAdminQueries: true,
  }
);

export function AdminPage() {
  return (
    <Admin
      basename="/panel"
      disableTelemetry
      authProvider={authProvider}
      dataProvider={dataProvider}
      loginPage={LoginPage}
      requireAuth
    >
      {(permissions) => (
        <>
          <Resource
            name="articles"
            icon={FeedIcon}
            list={NewsList}
            show={NewsShow}
            edit={permissions?.includes("admin") ? NewsEdit : null}
            create={permissions?.includes("admin") ? NewsCreate : null}
          />
          {permissions?.includes("admin") ? (
            <Resource
              name="users"
              options={{ label: "Members" }}
              icon={UserIcon}
              create={UserCreate}
              list={UserList}
              show={UserShow}
              edit={UserEdit}
            />
          ) : null}
          {permissions?.includes("superadmin") ? (
            <Resource
              name="cognitoUsers"
              icon={UserIcon}
              options={{ label: "Cognito Users" }}
              list={CognitoUserList}
              show={CognitoUserShow}
            />
          ) : null}
          {permissions?.includes("superadmin") ? (
            <Resource
              name="cognitoGroups"
              options={{ label: "Cognito Groups" }}
              list={CognitoGroupList}
            />
          ) : null}
        </>
      )}
    </Admin>
  );
}
