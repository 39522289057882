export const resources = {
  en: {
    translation: {
      next: "Next",
      title: "Polish Dermatoscopy Group",
      registerTitle: "Join PDG",
      register: {
        subtitle: "Create account",
        email: "Email address",
        password: "Password",
        terms: "I accept terms and conditions",
        thankyou: "Thank you for registartion.",
        thankyouSub: "Your will be notified when your status will change.",
      },
      contact: {
        subtitle: "Contact data",
        firstName: "First name",
        lastName: "Last name",
        personalNumber: "Personal number/ID number",
        birthDate: "Birth date",
        addressLine1: "Address line 1",
        addressLine2: "Address line 2",
        city: "City",
        state: "State/Provice/Region",
        zipCode: "Zip / Postal code",
        country: "Country",
        diplomaScan: "Diploma scan, confirming your medical degreee (PDF or image)",
        dragAndDrop: "Drag & drop",
        pwz: "PWZ"
      },
      privacy: {
        title: 'Privacy Policy',
      },
    },
  },
  pl: {
    translation: {
      next: "Dalej",
      title: "Polska Grupa Dermatoskopowa",
      registerTitle: "Rejestracja PGD",
      contact: {
        subtitle: "Dane kontaktowe",
        firstName: "Imię",
        lastName: "Nazwisko",
        personalNumber: "Numer PESEL",
        birthDate: "Data urodzenia",
        addressLine1: "Adres (pierwsza linia)",
        addressLine2: "Adres (druga linia)",
        city: "Miejscowość",
        state: "Województwo",
        zipCode: "Kod pocztowy",
        country: "Kraj",
        diplomaScan: "Skan/zdjęcie dyplomu lekarskiego (jeśli nie podano PWZ). Załącznik zostanie automatycznie usunięty po rozpatrzeniu wniosku.",
        dragAndDrop: "Drag & drop",
        pwz: "Numer prawa wykonywania zawodu PWZ"
      },
      privacy: {
        title: 'Polityka Prywatności',
      },
      register: {
        subtitle: "Dane konta",
        email: "Adres email",
        password: "Hasło",
        terms:
          "Rejestrując się akceptuję aktualny regulamin. Informuję, że zapoznałem się z polityką prywatności.",
        thankyou: "Konto zostało prawidłowo utworzone.",
        thankyouSub:
          "Zostaniesz poinformowany drogą mailową o statusie akceptacji.",
      },
    },
  },
};
