import { useEffect, useState } from "react";
import {
  Datagrid,
  DateField,
  Edit,
  EditButton,
  List,
  Show,
  BooleanField,
  BooleanInput,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  NumberInput,
  TextInput,
  DateInput,
  useRecordContext,
  required,
  Create,
} from "react-admin";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { AmplifyFileInput } from "react-admin-amplify";
import { Storage, API, graphqlOperation } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { Button, IconButton } from "@mui/material";
import * as mutations from "../graphql/mutations";

export const UserList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="userNumber" sortable={false} />
        <TextField source="username" sortable={false} />
        <BooleanField source="confirmed" sortable={false} />
        <TextField source="firstName" sortable={false} />
        <TextField source="lastName" sortable={false} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const DeleteButton = ({ onClick }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton aria-label="delete" onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm Delete File
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this file?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              onClick();
              handleClose();
            }}
            color="error"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const UserFiles = () => {
  const record = useRecordContext();
  const [files, setFiles] = useState([]);
  useEffect(() => {
    const fetchFiles = async () => {
      const files = await Promise.all(
        record?.documents?.map(async (document) => {
          const signedURL = await Storage.get(document.key);
          return {
            ...document,
            signedURL,
          };
        })
      );
      setFiles(files);
    };
    fetchFiles();
  }, [record]);

  console.log(record.documents)

  return files.map((file) => (
    <div>
      <a key={file.key} href={file.signedURL}>
        {file.key}
      </a>
      <DeleteButton key={file.key} onClick={async () => {
       await Storage.remove(file.key,  { level: "public" });
       const documents = record.documents.filter((document) => document.key !== file.key);
       await API.graphql(mutations.updateUser, { input: { id: record.id, documents } });
      }} />
    </div>
  ));
};

export const UserShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="userNumber" />
        <TextField source="username" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="pwz" />
        <DateField source="birthDate" />
        <TextField source="addressFirstLine" />
        <TextField source="addressSecondLine" />
        <TextField source="city" />
        <TextField source="state" />
        <TextField source="zipCode" />
        <TextField source="country" />
        <TextField source="pesel" />
        <BooleanField source="confirmed" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <UserFiles />
      </SimpleShowLayout>
    </Show>
  );
};

const Aside = () => {
  const [maxUserNumber, setMaxUserNumber] = useState(0);
  useEffect(() => {
    const getMaxUserNumber = async () => {
      const listUsers = await API.graphql(
        graphqlOperation(` 
      query ListUsers {
        listUsers(limit:999) {
          items {
            userNumber
          }
        }
      }
    `)
      );
      // find max userNumber
      const maxUserNumber = listUsers.data.listUsers.items
        .map((item) => (item.userNumber = parseInt(item.userNumber)))
        .reduce((max, userNumber) => (userNumber > max ? userNumber : max), 0);
      setMaxUserNumber(maxUserNumber);
    };
    getMaxUserNumber();
  }, []);

  return (
    <Box sx={{ width: "200px", margin: "1em" }}>
      <Typography variant="h6">Next number</Typography>
      <Typography variant="body2">
        <pre>{maxUserNumber + 1}</pre>
      </Typography>
    </Box>
  );
};

export const UserEdit = (props) => (
  <Edit {...props} aside={<Aside />}>
    <SimpleForm>
      <TextInput source="username" disabled />
      <NumberInput source="userNumber" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="pwz" />
      <DateInput source="birthDate" />
      <TextInput source="addressFirstLine" />
      <TextInput source="addressSecondLine" />
      <TextInput source="city" />
      <TextInput source="state" />
      <TextInput source="zipCode" />
      <TextInput source="country" />
      <TextInput source="pesel" />
      <BooleanInput source="confirmed" defaultValue={false} />
      <AmplifyFileInput
        source="documents"
        accept={["application/pdf", "image/*"]}
        multiple
        maxSize={5000000}
        fileFieldProps={{ title: "key" }}
        storageOptions={{ level: "public" }}
      />
    </SimpleForm>
  </Edit>
);

export const UserCreate = (props) => (
  <Create {...props} aside={<Aside />}>
    <SimpleForm>
      <TextInput source="id" defaultValue={uuidv4()} />
      <TextInput source="username" validate={required()} label="Email" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="pwz" />
      <DateInput source="birthDate" />
      <TextInput source="addressFirstLine" />
      <TextInput source="addressSecondLine" />
      <TextInput source="city" />
      <TextInput source="state" />
      <TextInput source="zipCode" />
      <TextInput source="country" />
      <TextInput source="pesel" />
      <BooleanInput source="confirmed" defaultValue={true} />
      <AmplifyFileInput
        source="documents"
        accept={["application/pdf", "image/*"]}
        multiple
        maxSize={5000000}
        fileFieldProps={{ title: "key" }}
        storageOptions={{ level: "public" }}
      />
    </SimpleForm>
  </Create>
);
