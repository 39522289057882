import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Copyright } from "./Copyright";
import { useTranslation } from "react-i18next";

const theme = createTheme();

export const Privacy = () => {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: "relative",
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            {t("title")}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography component="h1" variant="h4" align="center">
            {t("privacy.title")}
          </Typography>

          <Typography variant="body1">
            Informujemy, że Pana/Pani dane osobowe będą przetwarzane przez
            Organizację z siedzibą w Poznaniu (zwaną dalej Administratorem lub
            Organizacją) w celu kontaktu w związku z członkostwem w naszej
            Organizacji oraz informowania o realizacji jej celów statutowych, a
            także możliwości wspierania jej działalności. Podstawę prawną
            przetwarzania danych stanowi realizacja prawnie uzasadnionych
            interesów Administratora w postaci komunikacji z użytkownikami
            strony (art. 6 ust. 1 lit. f rozporządzenia Parlamentu Europejskiego
            i Rady (UE) 2016/679 z 27 kwietnia 2016 r. w sprawie ochrony osób
            fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
            swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
            (RODO). Podanie danych jest dobrowolne, ale niezbędne do tego, aby
            na bieżąco informować Pana/Panią o planowanych obradach organów
            naszej Organizacji, o realizacji jej celów statutowych, a także
            możliwości wspierania jej działalności. Pana/Pani dane będą
            przetwarzane nie dłużej, niż jest to konieczne dla powyższych celów,
            a po tym czasie mogą być przetwarzane przez okres przedawnienia
            ewentualnych roszczeń. Informujemy, że przysługuje Pani prawo
            dostępu do treści swoich danych osobowych, ich sprostowania,
            usunięcia lub ograniczenia przetwarzania, prawo do przenoszenia
            danych, prawo wniesienia sprzeciwu wobec ich przetwarzania, a także
            prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych
            Osobowych. Pana/Pani dane osobowe będą przechowywane przez naszą
            Organizację nie dłużej niż przez okres przedawnienia roszczeń z
            tytułu naruszenia przepisów o ochronie danych osobowych w razie
            otrzymania od Pana/Pani żądania usunięcia danych osobowych. Do Pani
            danych osobowych mogą mieć również dostęp podmioty świadczące na
            rzecz Organizacji usługi, w szczególności hostingowe, informatyczne,
            drukarskie, wysyłkowe, płatnicze, prawnicze, księgowe, kadrowe.
            Pana/Pani dane osobowe mogą być przekazywane do państwa trzeciego
            tj. poza Europejski Obszar Gospodarczy. Jednak nastąpi to wyłącznie
            jedynie w zakresie, na jaki będzie pozwalać prawo, w szczególności
            na podstawie decyzji Komisji Europejskiej stwierdzającej odpowiedni
            poziom ochrony lub standardowych klauzul umownych UE. W każdym
            wypadku Organizacja zapewnia możliwość uzyskania dalszych informacji
            i otrzymania kopii odpowiednich zabezpieczeń. Podane dane osobowe
            mogą być przetwarzane w sposób zautomatyzowany, w tym również w
            formie profilowania. Jednak decyzje dotyczące indywidualnej osoby,
            związane z tym przetwarzaniem nie będą zautomatyzowane. Ze
            szczegółowymi informacjami dotyczącymi zasad przetwarzania danych
            osobowych w naszej Organizacji może Pan/Pani zapoznać się w Polityce
            prywatności.
          </Typography>
        </Paper>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
};
