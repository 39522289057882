import * as React from "react";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as moment from "moment";
import { Storage, API, graphqlOperation } from "aws-amplify";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import FileUpload from "react-mui-fileuploader";
import { v4 as uuidv4 } from "uuid";
import * as mutations from "../graphql/mutations";
import awsExports from "../aws-exports";

export const CreateAccountForm = ({ handleNext }) => {
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      accept: false,
      firstName: "",
      lastName: "",
      personalNumber: "",
      birthDate: moment([1980]),
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
      files: [],
      pwz: ""
    },
    validationSchema: Yup.object({
      email: Yup.string("Enter your email")
        .email("Enter a valid email")
        .required("Email is required"),
      password: Yup.string("Enter your password")
        .min(8, "Password should be of minimum 8 characters length")
        .required("Password is required"),
      accept: Yup.boolean().oneOf(
        [true],
        "You must accept the terms and conditions"
      ),
      firstName: Yup.string("Enter your name").required(
        "First name is required"
      ),
      lastName: Yup.string("Enter your last name").required(
        "First name is required"
      ),
      personalNumber: Yup.string().required(),
      address1: Yup.string().optional(),
      address2: Yup.string().optional(),
      city: Yup.string().required(),
      country: Yup.string().required(),
      pwz: Yup.number(),
      files: Yup.array(),
      pwz_files: Yup.string().when(["pwz", "files"], {
        is: (pwz, files) => !pwz && files.length < 1,
        then: Yup.string().required("Either diploma or PWZ is required"),
      }),
    }),
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await Auth.signUp({
          username: values.email,
          password: values.password,
          attributes: {
            email: values.email,
          }
        });
        // login as user
        await Auth.signIn(values.email, values.password);
        // upload files
        const files = await Promise.all(
          values.files.map(async (file) => {
            const ext = file.name.split(".")[1];
            const result = await Storage.put(
              `${uuidv4().replace(/-/g, "")}.${ext}`,
              file,
              { level: "public" }
            );

            return {
              bucket: awsExports.aws_user_files_s3_bucket,
              region: awsExports.aws_user_files_s3_bucket_region,
              key: result.key,
            };
          })
        );
        // create
        const birthDate = values.birthDate.format("YYYY-MM-DD");
        await API.graphql(
          graphqlOperation(mutations.createUser, {
            input: {
              id: values.email, // overwritten by query
              username: values.email, // overwritten by query
              confirmed: false,
              firstName: values.firstName,
              lastName: values.lastName,
              birthDate: birthDate,
              addressFirstLine: values.address1,
              addressSecondLine: values.address2,
              city: values.city,
              state: values.state,
              zipCode: values.zipCode,
              country: values.country,
              pesel: values.personalNumber,
              documents: files,
              pwz: values.pwz,
            },
          })
        );
        handleNext();
      } catch (error) {
        setSubmitting(false);
        // see: https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_SignUp.html#API_SignUp_Errors
        let field = "email";
        if (error.name === "InvalidPasswordException") {
          field = "password";
        }
        setErrors({ [field]: error.message });
        debugger;
        console.error("error signing up:", error);
      }
    },
  });
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <React.Fragment>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                id="email"
                name="email"
                type="email"
                label={t("register.email")}
                fullWidth
                variant="standard"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="password"
                name="password"
                type="password"
                required
                label={t("register.password")}
                fullWidth
                variant="standard"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="firstName"
                name="firstName"
                label={t("contact.firstName")}
                fullWidth
                autoComplete="given-name"
                variant="standard"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="lastName"
                name="lastName"
                label={t("contact.lastName")}
                fullWidth
                autoComplete="family-name"
                variant="standard"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="personalNumber"
                name="personalNumber"
                label={t("contact.personalNumber")}
                fullWidth
                required
                variant="standard"
                value={formik.values.personalNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.personalNumber &&
                  Boolean(formik.errors.personalNumber)
                }
                helperText={
                  formik.touched.personalNumber && formik.errors.personalNumber
                }
              />
            </Grid>
            <Grid item xs={12}>
              <MobileDatePicker
                label={t("contact.birthDate")}
                inputFormat="DD/MM/YYYY"
                value={formik.values.birthDate}
                error={
                  formik.touched.birthDate && Boolean(formik.errors.birthDate)
                }
                helperText={formik.touched.birthDate && formik.errors.birthDate}
                onChange={(value) => {
                  formik.setFieldValue("birthDate", value);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth variant="standard" />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="address1"
                name="address1"
                label={t("contact.addressLine1")}
                fullWidth
                autoComplete="shipping address-line1"
                variant="standard"
                value={formik.values.address1}
                onChange={formik.handleChange}
                error={
                  formik.touched.address1 && Boolean(formik.errors.address1)
                }
                helperText={formik.touched.address1 && formik.errors.address1}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="address2"
                name="address2"
                label={t("contact.addressLine2")}
                autoComplete="shipping address-level2"
                fullWidth
                variant="standard"
                value={formik.values.address2}
                onChange={formik.handleChange}
                error={
                  formik.touched.address2 && Boolean(formik.errors.address2)
                }
                helperText={formik.touched.address2 && formik.errors.address2}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="city"
                name="city"
                label={t("contact.city")}
                fullWidth
                autoComplete="shipping city"
                variant="standard"
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="state"
                name="state"
                label={t("contact.state")}
                fullWidth
                variant="standard"
                value={formik.values.state}
                onChange={formik.handleChange}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.touched.state && formik.errors.state}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="zipCode"
                name="zipCode"
                label={t("contact.zipCode")}
                fullWidth
                autoComplete="shipping postal-code"
                variant="standard"
                value={formik.values.zipCode}
                onChange={formik.handleChange}
                error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                helperText={formik.touched.zipCode && formik.errors.zipCode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="country"
                name="country"
                label={t("contact.country")}
                fullWidth
                autoComplete="shipping country"
                variant="standard"
                value={formik.values.country}
                onChange={formik.handleChange}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
              />
            </Grid>
            <Grid item xs={12}>
              {(formik.touched.pwz || formik.touched.files) &&
              Boolean(formik.errors.pwz_files) ? (
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {formik.errors.pwz_files}
                </Alert>
              ) : null}
              <TextField
                id="pwz"
                name="pwz"
                label={t("contact.pwz")}
                fullWidth
                variant="standard"
                value={formik.values.pwz}
                onChange={formik.handleChange}
                error={formik.touched.pwz && Boolean(formik.errors.pwz)}
                helperText={formik.touched.pwz && formik.errors.pwz}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" component="span">
                {t("contact.diplomaScan")}
              </Typography>
              <FileUpload
                title=""
                multiFile={true}
                disabled={false}
                getBase64={false}
                header={t("contact.dragAndDrop")}
                maxFileSize={3}
                maxUploadFiles={0}
                onFilesChange={(files) => {
                  formik.setFieldValue("files", [...files]);
                }}
                allowedExtensions={["jpg", "jpeg", "png", "pdf"]}
                bannerProps={{ elevation: 0, variant: "outlined" }}
                containerProps={{ elevation: 0, variant: "plain" }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl error={true} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="accept"
                        name="accept"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    }
                    label={t("register.terms")}
                  />
                </FormGroup>
                <FormHelperText>
                  {formik.touched.accept && formik.errors.accept}
                </FormHelperText>
                <FormHelperText>
                  <Link to="/privacy" relative="path">
                    {t("privacy.title")}
                  </Link>
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <LoadingButton
                  loading={formik.isSubmitting}
                  variant="contained"
                  type="submit"
                  sx={{ mt: 3, ml: 1 }}
                >
                  {t("next")}
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </React.Fragment>
    </LocalizationProvider>
  );
};
