import { useState } from "react";
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  List,
  required,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  RichTextField,
  TextInput,
  usePermissions,
  TextField,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { v4 as uuidv4 } from "uuid";

export const NewsList = (props) => {
  const { permissions } = usePermissions();

  return (
    <List {...props}>
      <Datagrid>
        <TextField source="title" />
        <DateField source="createdAt" />
        <ShowButton />
        {permissions?.includes("admin") ? <EditButton /> : null}
      </Datagrid>
    </List>
  );
};

export const NewsShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="title" />
        <RichTextField source="name" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </SimpleShowLayout>
    </Show>
  );
};
const requiredValidator = [required()];

export const NewsEdit = (props) => (
  <Edit
    {...props}
    transform={(data) => {
      const { orders, ...rest } = data;
      return rest;
    }}
  >
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="title" validate={requiredValidator} />
      <RichTextInput source="body" stripTags validate={requiredValidator}  />
    </SimpleForm>
  </Edit>
);

export const NewsCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="id" defaultValue={uuidv4()} />
      <TextInput source="title" validate={requiredValidator}/>
      <RichTextInput source="body" stripTags validate={requiredValidator} />
    </SimpleForm>
  </Create>
);
