import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { Register } from "./components/Register";
import { AdminPage } from "./Admin";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { resources } from "./translations";
import { Privacy } from "./components/Privacy";
import LogRocket from "logrocket";

LogRocket.init("pinjsc/pgd");
Amplify.configure(awsExports);
i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "en",
    supportedLngs: ["pl", "en"],
    react: {
      wait: true,
    },
  });

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Register />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/panel/*" element={<AdminPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
