import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CreateAccountForm } from "./CreateAccountForm";
import { useTranslation } from "react-i18next";
import { Copyright } from "./Copyright";

const theme = createTheme();

export const Register = () => {
  const { t } = useTranslation();
  const [finish, setFinish] = React.useState(false);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: "relative",
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            {t("title")}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography component="h1" variant="h4" align="center">
            {t("registerTitle")}
          </Typography>
          <React.Fragment>
            {finish ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  {t("register.thankyou")}
                </Typography>
                <Typography variant="subtitle1">
                  {t("register.thankyouSub")}
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <CreateAccountForm
                  handleNext={() => {
                    setFinish(true);
                  }}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
};
